.wrapper-about-us-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    min-height: 100vh;
}

.wrapper-boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 15px 0;
    margin-top: 30px;
}

.box {
    padding: 0 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    transition: all ease-in-out 0.4s;
    min-height: 280px;
    max-width: 50%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 9px;
    background-color: var(--white-400);
    margin: 0 10px;
}

.box:hover {
    background: var(--blue-400);
    padding-bottom: 25px;
}

.box strong {
    display: block;
    font-size: 28px;
    font-weight: 700;
    color: var(--black-200);
    transition: all ease-in-out 0.4s;
}
.box:hover strong {
    color: var(--white-400);
}

.box span {
    color: var(--grey-400);
    font-size: 16px;
    margin: 0;
    padding: 0;
    line-height: 1.5rem;
    transition: all ease-in-out 0.4s;
}
.box:hover span {
    color: var(--white-400);
}

.wrapper-content-aside-text {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.wrapper-animation {
    width: 800px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -90px;
}

.wrapper-texts {
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-between;
    align-items: flex-start;
}

.wrapper-texts p {
    width: 100%;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    white-space: break-spaces;
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: var(--grey-400);
    font-size: 16px;
    margin: 0;
    padding: 0;
    max-width: 700px;
}

.wrapper-texts p svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

.wrapper-texts p svg path {
    fill: green;
}

@media (max-width: 1600px) {
    .wrapper-animation {
        margin-left: -40px;
    }
}

@media (max-width: 1380px) {
    .wrapper-animation {
        width: 500px;
    }
}

@media (max-width: 1280px) {
    .wrapper-animation {
        width: 500px;
    }
    .wrapper-texts {
        height: 100%;
    }
}

@media (max-width: 1180px) {
    .wrapper-content-aside-text {
        flex-direction: column;
    }
    .wrapper-texts {
        width: 100%;
        align-items: center;
    }

    .wrapper-animation {
        width: 600px;
        height: 100%;
        margin-left: 0px;
    }
}

@media (max-width: 1024px) {
    .wrapper-boxes {
        flex-direction: column;
    }
    .box {
        max-width: none;
        margin: 10px 10px;
    }
}

@media (max-width: 600px) {
    .wrapper-animation {
        width: 500px;
    }
}

@media (max-width: 550px) {
    .wrapper-animation {
        width: 400px;
    }

    .box strong {
        font-size: 25px;
    }
    .box span {
        font-size: 14px;
    }
}

@media (max-width: 375px) {
    .wrapper-texts p {
        font-size: 14px;
    }
    .wrapper-animation {
        width: 300px;
    }

    .box strong {
        margin-top: 10px;
        font-size: 20px;
    }
    .box span {
        margin-bottom: 10px;
    }
}

@media (max-width: 320px) {
    .wrapper-texts p {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 300px) {
    .box {
        min-width: none;
        margin: 10px 0;
    }

    .box strong {
        font-size: 16px;
    }
}

@media (max-width: 280px) {
    .wrapper-animation {
        width: 100%;
    }

    .wrapper-texts p {
        font-size: 13px;
    }
}

@media (hover: none) {
    .box:hover {
        background-color: var(--white-400);
        padding: 0 30px;
    }

    .box:hover strong {
        color: var(--black-200);
    }

    .box:hover span {
        color: var(--grey-400);
    }
}
