.wrapper-contact-us-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.content-wrapper-asides {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.content-wrapper-asides aside {
    display: flex;
    flex-direction: column;
}

.content-wrapper-asides form {
    display: flex;
    flex-direction: column;
}

.content-wrapper-asides form {
    flex: 0.7;
}

.content-wrapper-asides aside {
    flex: 0.3;
    background-color: var(--blue-400);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.personal-infos {
    color: var(--white-400);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px;
    max-height: 554px;
}

.personal-info-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;
}

.personal-info-details span {
    text-align: center;
    max-width: 340px;
    padding-left: 15px;
    font-weight: 500;
}

.personal-info-details svg {
    width: 26px;
    height: 26px;
    font-weight: 500;
}

.personal-info-details svg path {
    fill: var(--white-400);
}

.wrapper-clickable-links {
    display: flex;
    align-self: center;
    justify-content: flex-start;
    width: 100%;
    border-top: 1px solid var(--grey-300);
    margin-top: 20px;
    padding: 20px 0;
}

.clickable-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 60px;
    margin-right: 30px;
    font-weight: 400;
    justify-content: space-between;
}

.clickable-links svg {
    width: 30px;
    height: 30px;
}

.email-icon-style {
    align-self: center;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 7px;
    height: 20px;
    color: var(--red-400);
    background: var(--white-400);
}

.contact-infos {
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    width: 90%;
}

.button-submit {
    border: none;
    width: 100%;
    max-width: 140px;
    border-radius: 5px;
    background: var(--blue-400);
    border-radius: 5px;
    align-items: flex-end;
    align-self: flex-end;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
    color: var(--white-400);
    font-weight: 700;
    line-height: 14px;
    font-size: 18px;
    transition: 0.5s;
    border: 4px solid var(--blue-400);
}

.button-submit:hover {
    background: var(--white-400);
    border: 4px solid var(--blue-400);
    border-radius: 5px;
    color: var(--black-400);
}

.wrapper-inputs {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0;
    width: 100%;
}

.wrapper-content-inputs {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-right: 15px;
}

.wrapper-content-inputs label {
    font-size: 15px;
    font-weight: 500;
    color: var(--grey-400);
    cursor: pointer;
}

.wrapper-content-inputs input {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-400);
    height: 50px;
    width: 100%;
    border: none;
    margin-top: 10px;
    padding-left: 8px;
    transition: 0.5s;
    border-radius: 5px;
}

.wrapper-input-common div input {
    border: 2px solid var(--grey-300);
}

.message-error {
    justify-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5px 20px;
    flex: 1;
    display: flex;
    border-radius: 10px;
    background-color: var(--red-900);
}

.arrow-up {
    margin-top: 10px;
    margin-left: 24px;
    width: 0;
    height: 0;
    max-width: 0;
    max-height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--red-900);
}
.message-error span {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: white;
    font-weight: 500;
}

.wrapper-inputs .wrapper-input-error {
    border: 2px solid var(--red-900);
}

.wrapper-content-inputs input:focus {
    transition: 0.5s;
    border-color: var(--blue-800);
}

.wrapper-content-inputs input:hover {
    transition: 0.5s;
    border-color: var(--blue-800);
}

.wrapper-content-inputs input::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-500);
}

.wrapper-content-inputs textarea {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-400);
    height: 50px;
    width: 100%;
    height: 100%;
    min-height: 150px;
    max-height: 240px;
    border: none;
    margin-top: 10px;
    padding-top: 10px;
    padding-left: 8px;
    border-radius: 5px;
    resize: none;
    transition: 0.5s;
}

.wrapper-textarea-common div textarea {
    border: 2px solid var(--grey-300);
}

.wrapper-inputs .wrapper-textarea-error {
    border: 2px solid var(--red-900);
}

.wrapper-content-inputs textarea:focus {
    transition: 0.5s;
    border-color: var(--blue-800);
}

.wrapper-content-inputs textarea:hover {
    transition: 0.5s;
    border-color: var(--blue-800);
}

.wrapper-content-inputs textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: var(--grey-500);
}

.input-required label::after {
    position: absolute;
    content: "*";
    color: var(--red-900);
    margin-left: 5px;
}

@media (max-width: 2560px) {
    .wrapper-inputs {
        max-width: 1287px;
    }
}

@media (max-width: 1920px) {
    .wrapper-inputs {
        max-width: 947px;
    }
}

@media (max-width: 1690px) {
    .personal-info-details span {
        padding-left: 10px;
        text-align: start;
    }
}

@media (max-width: 1024px) {
    .content-wrapper-asides {
        flex-direction: column;
    }
    .contact-infos {
        width: 100%;
    }

    .content-wrapper-asides aside {
        border-radius: 5px;
    }

    .personal-info-details span {
        padding-left: 15px;
        text-align: center;
    }
}

@media (max-width: 700px) {
    .wrapper-clickable-links {
        justify-content: space-around;
    }
    .content-wrapper-asides {
        flex-direction: column-reverse;
    }
}

@media (max-width: 625px) {
    .wrapper-inputs {
        flex-direction: column;
        margin: 0;
        width: 100%;
    }
    .wrapper-content-inputs {
        flex-direction: column;
        margin: 0;
        margin: 10px 0;
        width: 100%;
    }
}

@media (max-width: 560px) {
    .personal-info-details span {
        padding-left: 10px;
        text-align: start;
    }
}

@media (max-width: 375px) {
    .personal-info-details {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        justify-items: center;
        width: 100%;
    }

    .message-error span {
        font-size: 13px;
    }

    .wrapper-clickable-links span {
        font-size: 15px;
    }
    .personal-info-details span {
        font-size: 14px;
        text-align: center;
        padding: 0;
        padding-top: 4px;
    }
    .personal-info-details svg {
        width: 23px;
        height: 23px;
    }
    .button-submit {
        max-width: 100px;
        height: 50px;

        line-height: 14px;
        font-size: 15px;
    }
}

@media (max-width: 355px) {
    .wrapper-clickable-links span {
        font-size: 14px;
        white-space: nowrap;
    }
    .wrapper-content-inputs label {
        font-size: 14px;
    }
}

@media (max-width: 250px) {
    .wrapper-clickable-links {
        justify-content: space-between;
        flex-direction: column-reverse;
        padding: 0;
        margin: 0;
    }
    .clickable-links {
        margin: 0;
        margin-top: 10px;
    }
    .message-error span {
        font-size: 12px;
    }
}

@media (hover: none) {
    .button-submit:hover {
        background: var(--blue-400);
        border: 4px solid var(--blue-400);
        border-radius: 5px;
        color: var(--white-400);
    }

    .wrapper-content-inputs input:hover {
        border: 2px solid var(--grey-300);
    }

    .wrapper-content-inputs textarea:hover {
        border: 2px solid var(--grey-300);
    }
}

@media (hover: none) {
    .button-submit:hover {
        background: var(--blue-400);
        border: 4px solid var(--blue-400);
        border-radius: 5px;
        color: var(--white-400);
    }
}
