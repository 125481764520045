.wrapper-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.wrapper-limit {
    width: 80%;
    padding: 2rem 1rem;
}

@media (max-width: 430px) {
    .wrapper-limit {
        width: 90%;
        padding: 2rem 0.5rem;
    }
}
