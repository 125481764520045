.wrapper-home-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

.wrapper-text {
    width: 40%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-weight: 400;
    color: var(--white-400);
    text-align: left;
    line-height: 35px;
}
.wrapper-text span {
    font-style: italic;
    padding: 0 5px;
    border-radius: 5px;
    background: var(--blue-400);
}

.align-items-animation {
    display: flex;
    align-self: center;
    align-items: center;
}

@media (max-width: 1280px) {
    .align-items-animation {
        width: 500px;
    }
}

@media (max-width: 1085px) {
    .wrapper-home-section {
        flex-direction: column-reverse;
        justify-content: space-around;
    }

    .wrapper-text {
        width: 80%;
        line-height: 30px;
    }
    .align-items-animation {
        width: 500px;
    }
}

@media (max-width: 950px) {
    .align-items-animation {
        width: 400px;
    }
}

@media (max-width: 725px) {
    .wrapper-text {
        font-size: 18px;
    }
}

@media (max-width: 630px) {
    .align-items-animation {
        width: 350px;
    }
    .wrapper-text {
        line-height: 27px;
        width: 90%;
    }
}

@media (max-width: 600px) {
    .align-items-animation {
        width: 320px;
    }
}

@media (max-width: 430px) {
    .wrapper-text {
        text-align: left;
    }
}

@media (max-width: 410px) {
    .wrapper-home-section {
        justify-content: center;
    }
    .wrapper-text {
        padding-top: 50%;
        height: 310px;
        max-height: 310px;
        font-size: 16px;
        line-height: 25px;
        text-align: left;
    }
}

@media (max-width: 320px) {
    .align-items-animation {
        width: 250px;
    }
    .wrapper-text {
        font-size: 14px;
    }
}

@media (max-width: 300px) {
    .wrapper-text {
        width: 100%;
    }
}

@media (max-width: 250px) {
    .align-items-animation {
        width: 100%;
    }
    .wrapper-text {
        line-height: 22px;
    }
}

@media (max-width: 200px) {
    .wrapper-text {
        line-height: 20px;
    }
}
