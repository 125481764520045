.header-container {
    width: 100%;
    height: 4rem;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: var(--black-400);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.3);
    transition: 1s;
}

.header-limit {
    height: 100%;
    width: 80%;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.wrapper-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 120px;
}

.wrapper-logo img {
    width: 250px;
    height: 250px;
}

.wrapper-logo strong {
    padding-left: 10px;
    text-align: center;
    font-weight: 500;
    color: var(--white-400);
    font-size: larger;
    visibility: visible;
    opacity: 1;
}

.wrapper-nav {
    height: 100%;
    display: flex;
    align-items: center;
}

.nav {
    color: var(--white-400);
    height: 60px;
    flex-direction: column;
    align-self: center;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: 0.6s;
    padding: 0 1.25rem;
    font-weight: 500;
    white-space: nowrap;
}

.nav:hover {
    background-color: var(--blue-400);
}

.container {
    display: inline-block;
    cursor: pointer;
    border: none;
    background-color: none;
    background-color: var(--black-400);
}

.nav-top,
.nav-center,
.nav-bottom {
    width: 25px;
    height: 4px;
    border-radius: 2px;
    background-color: var(--white-400);
    margin: 4px 0;
    transition: 0.4s;
}

.change .nav-top {
    transform: translate(0, 8px) rotate(-45deg);
}

.change .nav-center {
    opacity: 0;
}

.change .nav-bottom {
    transform: translate(0, -8px) rotate(45deg);
}

@media (max-width: 840px) {
    .nav {
        padding: 0 1rem;
    }
}

@media (max-width: 780px) {
    .nav {
        padding: 0 0.6rem;
    }
}

@media (max-width: 280px) {
    .wrapper-logo strong {
        font-size: medium;
    }
}

@media (max-width: 255px) {
    .wrapper-logo strong {
        padding-left: 5px;
        font-size: small;
    }
}

@media (max-width: 230px) {
    .wrapper-logo strong {
        visibility: hidden;
        opacity: 0;
        font-size: xx-small;
    }
}
