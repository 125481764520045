@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
    --font-size: "16px";
    --font-weight: 400;
    --font-family: "Roboto", sans-serif;
    --background-color-default: #f2f2f2;

    --grey-300: #e0e0e0;
    --grey-500: #898989;
    --grey-400: #656563;
    --black-200: #282829;
    --black-400: #11111f;
    --black-900: #000000;
    --white-400: #ffffff;
    --blue-400: #59cbe8;
    --blue-800: #004af7;
    --red-400: #db4a39;
    --red-900: #f70000;
}

* {
    padding: 0;
    margin: 0;
    outline: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    height: 100%;
    background-color: var(--background-color-default) !important;
    font-size: var(--font-size) !important;
    font-family: var(--font-family) !important;
    font-weight: var(--font-weight) !important;
}

@media (max-height: 800px) and (min-width: 384px) {
    html,
    body {
        font-size: 95%;
    }
}
@media (max-height: 700px) and (min-width: 384px) {
    html,
    body {
        font-size: 90%;
    }
}
@media (max-height: 600px) and (min-width: 384px) {
    html,
    body {
        font-size: 85%;
    }
}

a {
    color: inherit;
    text-decoration: none;
}

button,
a {
    cursor: pointer;
}

input,
textarea,
button,
a,
span {
    font-family: var(--font-family);
}

/* width */
::-webkit-scrollbar {
    width: 0;
    visibility: hidden;
    opacity: 0;
}
/* Track */
::-webkit-scrollbar-track {
    width: 0;
    visibility: hidden;
    opacity: 0;
}
/* Handle */
::-webkit-scrollbar-thumb {
    width: 0;
    visibility: hidden;
    opacity: 0;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    width: 0;
    visibility: hidden;
    opacity: 0;
}
