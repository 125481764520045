.footer {
    width: 100%;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-items: center;
    background-color: var(--black-400);
    color: var(--white-400);
    transition: 0.5s ease-in;
    font-size: 14px;
    text-align: center;
    margin-top: 15px;
}

.footer div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 10px;
}

.back-nav-link {
    padding: 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--blue-400);
    transition: 0.5s;
    background-color: transparent;
}

.back-nav-link svg {
    width: 20px;
    height: 20px;
}

.back-nav-link:hover {
    background: var(--blue-400);
}

@media (hover: none) {
    .back-nav-link:hover {
        background-color: transparent;
    }
}

.footer-details {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.4s;
}

.footer-details a {
    padding-left: 10px;
    color: var(--white-400);
}

.footer-details a:hover {
    color: var(--blue-400);
}

@media (max-width: 400px) {
    .back-nav-link {
        padding: 10px 10px;
    }
}

@media (max-width: 340px) {
    .footer-details {
        flex-direction: column;
    }
}

@media (max-width: 300px) {
    .footer {
        flex-direction: column-reverse;
        height: 100%;
    }
    .back-nav-link {
        margin-bottom: 10px;
    }
}

@media (max-width: 270px) {
    .footer div {
        font-size: 14px;
    }
}

@media (max-width: 230px) {
    .footer div {
        font-size: 12px;
    }
}
